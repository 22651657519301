<template>
  <div class="login-form login-signin d-flex flex-column justify-content-between">
    <div class="mb-10 mt-lg-25">
      <img src="/image/side-logo.png"
           class="mb-10"
           alt="logo" />
      <h3 class="font-size-h1 text-bold">{{ $ml.get('passwordReset') }}</h3>
      <p class="text-muted font-size-secondary font-weight-semi-bold">
        {{ $ml.get('didYouComeWrong') }}
        <router-link class="text-bold font-size-3 ml-2"
                     :to="{ name: 'login' }">{{ $ml.get('signIn') }}</router-link>
      </p>
    </div>

    <!--begin::Signup-->
    <div class="login-form login-signin mt-10 mb-3"
         v-show="!showConfirmCode">
      <!--begin::Form-->
      <b-form class="form"
              @submit.stop.prevent="onSubmit">
        <b-form-group id="email-input-group"
                      :label="$ml.get('emailAddress')"
                      label-for="email-input">
          <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                        id="email-input"
                        name="email"
                        v-model.trim="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-input-live-feedback"
                        :placeholder="$ml.get('emailAddress')"></b-form-input>

          <b-form-invalid-feedback id="email-input-live-feedback">{{
            $ml.get('emailIsRequiredInfoText')
          }}</b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap flex-center p-0">
          <button type="submit"
                  ref="password-reset_submit"
                  class="btn btn-primary w-100 text-bold d-block px-9 py-3 my-3">
            {{ $ml.get('passwordReset') }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->

    <div v-show="showConfirmCode">
      <div class="code-text">
        <p class="text-dark font-size-secondary mb-5 font-weight-bold">
          {{ $ml.get('confirmation') }}
        </p>
      </div>

      <!--begin::ConfirmCode-->
      <div class="login-form login-signin mt-10 mb-3">
        <!--begin::Form-->
        <b-form class="form"
                @submit.stop.prevent="onSubmitConfirm">

          <b-form-group id="emailToken-input-group"
                        label-for="emailToken-input">
            <label>{{$ml.get('confirmCodeForEmail')}}
              <i class="fas fa-info-circle fs-12"
                 v-b-modal.password-reset-mail-modal></i></label>

            <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                          id="emailToken-input"
                          name="emailToken"
                          v-model.trim="$v.confirmForm.emailToken.$model"
                          :state="validateStateConfirm('emailToken')"
                          aria-describedby="emailToken-input-live-feedback"
                          :placeholder="$ml.get('confirmCodeForEmail')"
                          @blur="mailTokenVerifyApi()"
                          :class="
                this.confirmForm.emailToken === ''
                  ? ''
                  : this.showApproveEmailError
                  ? 'is-invalid'
                  : 'is-valid'
              "></b-form-input>

            <b-form-invalid-feedback id="emailToken-input-live-feedback"
                                     v-if="this.confirmForm.emailToken === ''">{{
                $ml.get('confirmCodeForEmailRequired')
              }}</b-form-invalid-feedback>
            <b-form-invalid-feedback id="emailToken-input-live-feedback"
                                     v-else>{{
                $ml.get('confirmationCodeIsInvalid')
              }}</b-form-invalid-feedback>
          </b-form-group>
          <div v-show="is_cellphone_validated">
            <b-form-group id="phoneToken-input-group"
                          label-for="phoneToken-input">
              <label>{{$ml.get('confirmCodeForPhone')}}
                <i class="fas fa-info-circle fs-12"
                   v-b-modal.password-reset-phone-modal></i></label>

              <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                            id="phoneToken-input"
                            name="phoneToken"
                            v-model.trim="$v.confirmForm.phoneToken.$model"
                            :state="validateStateConfirm('phoneToken')"
                            aria-describedby="phoneToken-input-live-feedback"
                            :placeholder="$ml.get('confirmCodeForPhone')"
                            @blur="phoneTokenVerifyApi()"
                            :class="
                  this.confirmForm.phoneToken === ''
                    ? ''
                    : this.showApprovePhoneError
                    ? 'is-invalid'
                    : 'is-valid'
                "></b-form-input>

              <b-form-invalid-feedback id="phoneToken-input-live-feedback"
                                       v-if="this.confirmForm.phoneToken === ''">{{
                  $ml.get('confirmCodeForPhoneRequired')
                }}</b-form-invalid-feedback>
              <b-form-invalid-feedback id="phoneToken-input-live-feedback"
                                       v-else>{{
                  $ml.get('confirmationCodeIsInvalid')
                }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group id="password-input-group"
                        :label="$ml.get('newPassword')"
                        label-for="password-input">
            <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                          :type="confirmForm.showPassword ?'text':'password'"
                          id="password-input"
                          name="password"
                          v-model.trim="$v.confirmForm.password.$model"
                          :state="validateStateConfirm('password')"
                          @input="changePassword"
                          aria-describedby="password-input-live-feedback"
                          :placeholder="$ml.get('enterYourNewPassword')"></b-form-input>

            <i :class="['far', {'fa-eye-slash' : confirmForm.showPassword,'fa-eye' : !form.showPassword }]"
               @click="confirmForm.showPassword = !confirmForm.showPassword"></i>

            <b-form-invalid-feedback id="password-input-live-feedback">{{
              $ml.get('newPasswordRequired')
            }}</b-form-invalid-feedback>
          </b-form-group>

          <PasswordStrengthViewer :form="confirmForm"
                                  :validPassword="validPassword"></PasswordStrengthViewer>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap flex-center p-0 m-0">
            <button type="submit"
                    class="btn btn-primary w-100 text-bold d-block px-9 py-3 my-3">
              {{ $ml.get('confirm') }}
            </button>
          </div>
          <a href="#"
             @click="reSendTokenApi()">{{
            $ml.get('receiveYourCodeSendItAgain')
          }}</a>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::ConfirmCode-->
    </div>

    <AuthFooter />
    <PasswordResetMailModal></PasswordResetMailModal>
    <PasswordResetPhoneModal></PasswordResetPhoneModal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
  email,
  required,
  requiredIf,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators'
import ApiService from '@/core/services/api.service'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'
import PasswordStrengthViewer from '@/components/PasswordStrengthViewer'
import PasswordResetMailModal from '@/components/auth/PasswordResetMailModal'
import PasswordResetPhoneModal from '@/components/auth/PasswordResetPhoneModal'
import AuthFooter from '@/components/auth/AuthFooter'
const basicValidate = require('basic-validate-js')

export default {
  name: 'register',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: '',
      },
      confirmForm: {
        emailToken: '',
        phoneToken: '',
        password: '',
        showPassword: false,
      },
      validPassword: {
        strongNumber: 0,
        isMinOneUpper: false,
        isMinOneLower: false,
        isMinEightChar: false,
        isMinOneNumber: false,
        isMinOneSpecial: false,
      },
      showConfirmCode: false,
      is_cellphone_validated: false,
      approveEmail: false,
      approvePhone: false,
      showApproveEmailError: false,
      showApprovePhoneError: false,
    }
  },
  validations: {
    form: { email: { required, email } },
    confirmForm: {
      emailToken: { required },
      phoneToken: {
        required: requiredIf(function () {
          return this.is_cellphone_validated
        }),
      },
      password: {
        required,
        minLength: minLength(8),
        strongPassword(password) {
          return (
            /[0-9]/g.test(password) && // checks is contain num
            /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(password) && // checks is contain special
            /[A-Z]/g.test(password) && // checks is contain upper
            /[a-z]/g.test(password) // checks is contain lower
          )
        },
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    validateStateConfirm(name) {
      const { $dirty, $error } = this.$v.confirmForm[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.resetPasswordApi()
    },
    onSubmitConfirm() {
      this.$v.confirmForm.$touch()
      if (this.$v.confirmForm.$anyError) {
        return
      }
      this.setNewPasswordApi()
    },
    resetPasswordApi() {
      ApiService.post('reset-password-initiate', {
        email: this.form.email,
      })
        .then((res) => {
          this.userVerificationApi()
          this.showConfirmCode = true
        })
        .catch((err) => {
          if (err == 'Error') {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }
        })
    },
    reSendTokenApi() {
      ApiService.post('reset-password-initiate', {
        email: this.form.email,
      })
        .then((res) => {
          this.$generateToast(
            this,
            'success',
            this.$ml.get('confirmationCodeSentAgain')
          )
        })
        .catch((err) => {
          if (err == 'Error') {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }
        })
    },
    userVerificationApi() {
      this.$store
        .dispatch(VERIFY_REGISTERED_USER, this.form.email)
        .then((res) => {
          this.is_cellphone_validated = res.is_cellphone_validated
          this.approvePhone = !res.is_cellphone_validated
          if (res.default_locale) {
            this.$ml.change(res.default_locale)
          }
        })
    },
    mailTokenVerifyApi() {
      if (this.showConfirmCode) {
        if (this.confirmForm.emailToken === '') {
          if (this.approveEmail === true) {
            this.approveEmail = false
            this.showApproveEmailError = true
          }
        } else {
          ApiService.post('validate-password-reset/for-email', {
            email: this.form.email,
            token: this.confirmForm.emailToken,
          })
            .then((res) => {
              this.approveEmail = true
              this.showApproveEmailError = false
            })
            .catch((err) => {
              this.approveEmail = false
              this.showApproveEmailError = true
            })
        }
      }
    },
    phoneTokenVerifyApi() {
      if (this.showConfirmCode) {
        if (this.confirmForm.phoneToken === '') {
          if (this.approvePhone === true) {
            this.approvePhone = false
            this.showApprovePhoneError = true
          }
        } else {
          ApiService.post('validate-password-reset/for-sms', {
            email: this.form.email,
            token: this.confirmForm.phoneToken,
          })
            .then((res) => {
              this.approvePhone = true
              this.showApprovePhoneError = false
            })
            .catch((err) => {
              this.approvePhone = false
              this.showApprovePhoneError = true
            })
        }
      }
    },
    setNewPasswordApi() {
      if (this.approveEmail && this.approvePhone) {
        let datas = {
          email: this.form.email,
          mail_token: this.confirmForm.emailToken,
          password: this.confirmForm.password,
        }

        if (this.is_cellphone_validated) {
          datas.sms_token = this.confirmForm.phoneToken
        }

        ApiService.put('reset-password', datas)
          .then((res) => {
            this.$generateToast(
              this,
              'success',
              this.$ml.get('yourPasswordHasBeenChangedSuccessfully')
            )
            setTimeout(() => this.$router.push({ path: '/login' }), 2000)
          })
          .catch((err) => {
            if (err == 'Error') {
              this.$generateToast(
                this,
                'danger',
                this.$ml.get('anErrorHasOccurred')
              )
            }
          })
      }
    },
    changePassword() {
      // password control regex
      let isContainNumReg = /[0-9]/g
      let isContainSpecialReg = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
      let isContainUpper = /[A-Z]/g
      let isContainLower = /[a-z]/g

      // set valid
      if (this.confirmForm.password.length >= 8) {
        this.validPassword.isMinEightChar || this.validPassword.strongNumber++
        this.validPassword.isMinEightChar = true
      } else {
        !this.validPassword.isMinEightChar || this.validPassword.strongNumber--
        this.validPassword.isMinEightChar = false
      }

      if (
        basicValidate.patternControl(this.confirmForm.password, isContainNumReg)
      ) {
        this.validPassword.isMinOneNumber || this.validPassword.strongNumber++
        this.validPassword.isMinOneNumber = true
      } else {
        !this.validPassword.isMinOneNumber || this.validPassword.strongNumber--
        this.validPassword.isMinOneNumber = false
      }

      if (
        basicValidate.patternControl(
          this.confirmForm.password,
          isContainSpecialReg
        )
      ) {
        this.validPassword.isMinOneSpecial || this.validPassword.strongNumber++
        this.validPassword.isMinOneSpecial = true
      } else {
        !this.validPassword.isMinOneSpecial || this.validPassword.strongNumber--
        this.validPassword.isMinOneSpecial = false
      }

      if (
        basicValidate.patternControl(this.confirmForm.password, isContainUpper)
      ) {
        this.validPassword.isMinOneUpper || this.validPassword.strongNumber++
        this.validPassword.isMinOneUpper = true
      } else {
        !this.validPassword.isMinOneUpper || this.validPassword.strongNumber--
        this.validPassword.isMinOneUpper = false
      }

      if (
        basicValidate.patternControl(this.confirmForm.password, isContainLower)
      ) {
        this.validPassword.isMinOneLower || this.validPassword.strongNumber++
        this.validPassword.isMinOneLower = true
      } else {
        !this.validPassword.isMinOneLower || this.validPassword.strongNumber--
        this.validPassword.isMinOneLower = false
      }
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
  },
  watch: {},
  components: {
    PasswordStrengthViewer,
    PasswordResetMailModal,
    PasswordResetPhoneModal,
    AuthFooter,
  },
}
</script>

<style lang="scss"  >
.login-form {
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
  .fs-12 {
    font-size: 1.2rem;
  }
  .btn-primary {
    border-radius: 6px;
  }
  #password-input-group {
    position: relative;
    i {
      position: absolute;
      cursor: pointer;
      color: #758196;
      top: 50px;
      right: 40px;
    }
  }
  .form-group label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #2f3044 !important;
  }
}
</style>
