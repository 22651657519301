<template>
  <b-modal
    id="password-reset-phone-modal"
    centered 
    hide-footer
    size="lg"
    :title="$ml.get('confirmCodeForPhone')"
  >
    <div class="font-weight-bold mb-5">
      {{ $ml.get('confirmCodeForPhoneDesc') }}
    </div>
    <hr>
    <div class="text-center">
      <img src="/image/password-reset/password-reset-sms.png" class="sample" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PasswordResetPhoneModal',
}
</script> 


<style lang="scss">
#password-reset-phone-modal {
  .sample {
      max-width: 100%;
  }
}
</style>
