<template>
  <b-modal
    id="password-reset-mail-modal"
    centered
    hide-footer
    size="lg"
    :title="$ml.get('confirmCodeForEmail')"
  >
    <div class="font-weight-bold mb-5">
      {{ $ml.get('confirmCodeForEmailDesc') }}
    </div>
    <hr />
    <div class="text-center">
      <img src="/image/password-reset/password-reset-mail.png" class="sample" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PasswordResetMailModal',
}
</script> 


<style lang="scss">
#password-reset-mail-modal {
  .sample {
    max-width: 100%;
  }
}
</style>
